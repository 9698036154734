import React from 'react';
import { DiJavascript } from 'react-icons/di';
import {
  SiTailwindcss,
  SiNodedotjs,
  SiPhp,
  SiPostgresql,
  SiMongodb,
  SiAmazonaws,
  SiCss3,
  SiBootstrap,
  SiHtml5,
  SiPython,
} from 'react-icons/si';

import { FaReact } from 'react-icons/fa';

const Experience = () => {
  return (
    <section id='experience' className='2xl:w-9/12 2xl:mx-auto'>
      <h5 className='text-xs'>Algunas de mis</h5>
      <h2 className='text-xl'>Habilidades</h2>

      <div className='container experience__container grid sm:grid-cols-2 gap-8'>
        <div className='bg-bgVariant py-[1rem] px-[1rem] rounded-[2rem] border-2 border-solid border-transparent transition-transition hover:bg-transparent hover:border-2 hover:border-solid hover:cursor-default hover:border-gray-600'>
          <h3 className='text-center mb-4 text-colorPrimary text-lg'>
            Desarrollo Frontend
          </h3>
          <div className='grid grid-cols-2 gap-3'>
            <article className='flex gap-3'>
              <FaReact className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>React</h4>
                <small className='text-light'>Intermedio</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <DiJavascript className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Intermedio</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiTailwindcss className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>Tailwind</h4>
                <small className='text-light'>Intermedio</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiBootstrap className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>Bootstrap</h4>
                <small className='text-light'>Básico</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiCss3 className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Intermedio</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiHtml5 className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Avanzado</small>
              </div>
            </article>
          </div>
        </div>
        <div className='bg-bgVariant py-[1rem] px-[1rem] rounded-[2rem] border-2 border-solid border-transparent transition-transition hover:bg-transparent hover:border-2 hover:border-solid hover:cursor-default hover:border-gray-600'>
          <h3 className='text-center mb-4 text-colorPrimary text-lg'>
            Desarrollo Backend
          </h3>
          <div className='grid grid-cols-2 gap-3'>
            <article className='flex gap-3'>
              <SiNodedotjs className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>NodeJS</h4>
                <small className='text-light'>Avanzado</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiMongodb className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>MongDB</h4>
                <small className='text-light'>Intermedio</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiPhp className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>PHP</h4>
                <small className='text-light'>Intermedio</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiPostgresql className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>PostgreSQL</h4>
                <small className='text-light'>Intermedio</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiPython className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>Python</h4>
                <small className='text-light'>Básico</small>
              </div>
            </article>
            <article className='flex gap-3'>
              <SiAmazonaws className='mt-[6px] text-colorPrimary' size={20} />
              <div>
                <h4>AWS</h4>
                <small className='text-light'>Básico</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
