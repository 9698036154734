import React from 'react';
// import IMGAvatar from "../../assets/testimonial/avatar-testimonial.png";
import IMGKendall from '../../assets/testimonial/photo-kendall.jpeg';
import IMGMario from '../../assets/testimonial/photo-mario.jpeg';
import IMGRuben from '../../assets/testimonial/photo-ruben.png';
// import IMGRaul from '../../assets/testimonial/photo-raul.jpeg';

// Swiper Library
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Swiper Styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const data = [
  {
    avatar: IMGMario,
    name: 'Mario Pastor',
    company: 'Digital Manager',
    review:
      'Percy es un crack en temas de desarrollo web y no solo programa sino que además brinda muy buenos consejos para la ejecución de cada proyecto.',
  },
  {
    avatar: IMGKendall,
    name: 'Kendall Vargas',
    company: 'Diseñador Gráfico',
    review:
      'Percy conoce muy bien el mundo del desarrollo de software, pudo ayudarme con mi proyecto de manera rápida y eficiente y estoy muy contento con el servicio que me brindo.',
  },
  {
    avatar: IMGRuben,
    name: 'Rubén Ibañez',
    company: 'Fotografo Profesional',
    review:
      'El nivel de profesionalismo mostrado en todos los trabajos que hemos realizado es extremadamente impresionante. No solo a nivel profesional sino también a nivel personal.',
  },
];

const Testimonials = () => {
  return (
    <section id='testimonials' className='2xl:w-6/12 2xl:mx-auto'>
      <h5 className='text-xs'>Algunos</h5>
      <h2 className='text-xl'>Testimonios</h2>

      <Swiper
        className='container border-4 border-white'
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, company, review }, index) => {
          return (
            <SwiperSlide
              key={index}
              className='bg-bgVariant h-auto text-center p-8 rounded-3xl select-none	'
            >
              <div className='w-16 aspect-square	overflow-hidden rounded-[50%] mx-auto my-4 mt-0 border-3'>
                <img src={avatar} alt='Avatar' />
              </div>
              <h4 className='text-base'>{name}</h4>
              <h6 className='text-xs font-extralight'>{company}</h6>
              <small className='client__review font-light	block w-[90%] text-slate-300 mx-auto mt-4 mb-2'>
                {review}
              </small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
