import React from "react";

// import { FiInstagram } from 'react-icons/fi';
import { IoLogoTwitter } from "react-icons/io";
import { FaGithub, FaYoutube, FaInstagram } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";

// import Logo from '../../assets/logotype.png';

const Footer = () => {
  return (
    <footer className="bg-[#0D87A4] text-center text-sm mt-28 py-8">
      {/* <a href='/' className='text-3xl font-normal inline-block'>
        <img src={Logo} alt='Logotype' className='w-9/12 mx-auto md:w-6/12' />
      </a> */}
      <ul className="flex flex-wrap justify-center gap-8 my-10 mx-12">
        <li className="">
          <a href="/" className="text-colorWhite hover:text-black">
            Inicio
          </a>
        </li>
        <li>
          <a href="#about" className="text-colorWhite hover:text-black">
            Acerca de
          </a>
        </li>
        <li>
          <a href="#experience" className="text-colorWhite hover:text-black">
            Experiencia
          </a>
        </li>
        <li>
          <a href="#portfolio" className="text-colorWhite hover:text-black">
            Portafolio
          </a>
        </li>
        <li>
          <a href="#testimonials" className="text-colorWhite hover:text-black">
            Testimonios
          </a>
        </li>
        <li>
          <a href="#contact" className="text-colorWhite hover:text-black">
            Contacto
          </a>
        </li>
        <li>
          <a
            href="https://blog.percybyte.com"
            target="_blank"
            rel="noreferrer"
            className="text-colorWhite hover:text-black"
          >
            Blog
          </a>
        </li>
      </ul>
      <div className="footer__socials flex justify-center gap-4 mb-8">
        <a
          href="https://linkedin.com/in/percybyte"
          target={"_blank"}
          rel={"noreferrer"}
          className="bg-bg text-white p-3 rounded-xl flex border-2 hover:bg-slate-600 hover:text-white border-bgVariant"
        >
          <BsLinkedin />
        </a>
        <a
          href="https://github.com/percybyte"
          target={"_blank"}
          rel={"noreferrer"}
          className="bg-bg text-white p-3 rounded-xl flex border-2 hover:bg-slate-600 hover:text-white border-bgVariant"
        >
          <FaGithub />
        </a>

        <a
          href="https://twitter.com/percybyte"
          target={"_blank"}
          rel={"noreferrer"}
          className="bg-bg text-white p-3 rounded-xl flex border-2 hover:bg-slate-600 hover:text-white border-bgVariant"
        >
          <IoLogoTwitter />
        </a>

        <a
          href="https://youtube.com/@percybyte"
          target={"_blank"}
          rel={"noreferrer"}
          className="bg-bg text-white p-3 rounded-xl flex border-2 hover:bg-slate-600 hover:text-white border-bgVariant"
        >
          <FaYoutube />
        </a>

        <a
          href="https://instagram.com/percybyte"
          target={"_blank"}
          rel={"noreferrer"}
          className="bg-bg text-white p-3 rounded-xl flex border-2 hover:bg-slate-600 hover:text-white border-bgVariant"
        >
          <FaInstagram />
        </a>
      </div>
      <div className="mb-24 text-white">
        <small>
          {new Date().getFullYear().toString()} &copy; PERCYBYTE. Todos los
          derechos reservados
        </small>
      </div>
    </footer>
  );
};

export default Footer;
