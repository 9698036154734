import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";

const Contact = () => {
  const form = useRef();
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [showMessageConfirmed, setShowMessageConfirmed] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoadingMessage(true);
    emailjs
      .sendForm(
        "service_vhp17r4",
        "template_4cj8kej",
        form.current,
        "q1HctHnkDMuDbr9Ix"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setTimeout(() => {
      setLoadingMessage(false);
      setShowMessageConfirmed(true);
      setTimeout(() => {
        setShowMessageConfirmed(false);
      }, 2000);
    }, 2000);
  };

  return (
    <section id="contact" className="2xl:w-9/12 2xl:mx-auto">
      <h5 className="text-xs">¿Alguna duda?</h5>
      <h2 className="text-xl">Conversemos</h2>

      <div className="container flex flex-col md:flex-row gap-8">
        <div className="flex flex-col gap-5 md:w-4/12">
          <article className="bg-bgVariant border-1 border-solid p-5 rounded-2xl text-center transition-transition hover:bg-[#3d598a44] hover:border-bgVariant hover:border-solid hover:border-1 hover:cursor-text">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>info@percybyte.com</h5>
            <a
              href="mailto:contact@percycode.com"
              target={"_blank"}
              rel={"noreferrer"}
              className="mt-3 inline-block text-sm"
            >
              Enviar mensaje
            </a>
          </article>
          <article className="bg-bgVariant border-1 border-solid p-5 rounded-2xl text-center transition-transition hover:bg-[#3d598a44] hover:border-bgVariant hover:border-solid hover:border-1 hover:cursor-text">
            <RiMessengerLine className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>@percytejada.code</h5>
            <a
              href="https://m.me/percytejada.code"
              target={"_blank"}
              rel={"noreferrer"}
              className="mt-3 inline-block text-sm"
            >
              Enviar mensaje
            </a>
          </article>
          <article className="bg-bgVariant border-1 border-solid p-5 rounded-2xl text-center transition-transition hover:bg-[#3d598a44] hover:border-bgVariant hover:border-solid hover:border-1 hover:cursor-text">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+51 971 1762 51</h5>
            <a
              href="https://api.whatsapp.com/send?phone=51971176251"
              target={"_blank"}
              rel={"noreferrer"}
              className="mt-3 inline-block text-sm"
            >
              Enviar mensaje
            </a>
          </article>
        </div>
        {/* End of contact options */}
        <form
          ref={form}
          onSubmit={sendEmail}
          className="flex flex-col gap-5 md:w-8/12"
        >
          {loadingMessage && <p>Enviando mensaje...</p>}
          {showMessageConfirmed && <p>Tu mensaje ha sido enviado</p>}
          <input
            type="text"
            name="name"
            placeholder="Ingresa tu nombre y apellido"
            required
            className="p-2 rounded-lg bg-transparent border-solid border-2 border-colorPrimaryVariant resize-none"
          />
          <input
            type="email"
            name="email"
            placeholder="Ingresa tu correo electrónico"
            required
            className="p-2 rounded-lg bg-transparent border-solid border-2 border-colorPrimaryVariant resize-none"
          />
          <textarea
            name="message"
            id=""
            cols="7"
            rows="10"
            placeholder="Escribe tu mensaje"
            required
            className="p-2 rounded-lg bg-transparent border-solid border-2 border-colorPrimaryVariant resize-none"
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Enviar
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
