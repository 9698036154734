import React, { useState } from 'react';
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai';
import {
  MdWork,
  MdOutlineLocalOffer,
  MdPermContactCalendar,
} from 'react-icons/md';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');

  return (
    <nav className='bg-black max-w-max z-10 fixed py-[0.7rem] px-[.7rem] left-1/2 bottom-4 -translate-x-[50%] flex gap-3 rounded-[3rem] backdrop-blur-lg'>
      <a
        href='#header'
        onClick={() => setActiveNav('#')}
        className={activeNav === '#' ? 'active' : ''}
      >
        <AiOutlineHome />
      </a>
      <a
        href='#about'
        onClick={() => setActiveNav('#about')}
        className={activeNav === '#about' ? 'active' : ''}
      >
        <AiOutlineUser />
      </a>
      <a
        href='#experience'
        onClick={() => setActiveNav('#experience')}
        className={activeNav === '#experience' ? 'active' : ''}
      >
        <MdWork />
      </a>
      <a
        href='#portfolio'
        onClick={() => setActiveNav('#services')}
        className={activeNav === '#services' ? 'active' : ''}
      >
        <MdOutlineLocalOffer />
      </a>
      <a
        href='#contact'
        onClick={() => setActiveNav('#contact')}
        className={activeNav === '#contact' ? 'active' : ''}
      >
        <MdPermContactCalendar />
      </a>
    </nav>
  );
};

export default Nav;
