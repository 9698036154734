import React from 'react';
import CV from '../../assets/resume.pdf';

const CTA = () => {
  return (
    <div className='flex flex-col items-center gap-5 justify-center md:flex-row'>
      <a href={CV} className='btn' target='_blank' rel='noreferrer'>
        Descargar CV
      </a>
      <a href='#contact' className='btn btn-primary'>
        Conversemos
      </a>
    </div>
  );
};

export default CTA;
