import React from "react";
import PhotoSN from "../../assets/profile-photo.png";
import CTA from "../header/CTA";

const About = () => {
  return (
    <section id="about" className="2xl:w-9/12 2xl:mx-auto">
      <h5 className="text-xs">Un poco</h5>
      <h2 className="text-xl">Sobre mi</h2>
      <div className="container grid gap-8">
        <div className=" rounded-full p-[1px] grid place-items-center">
          <div className=" border-yellow-300 border-2 overflow-hidden sm:flex sm:justify-center">
            <img
              src={PhotoSN}
              alt="Avatar"
              className="sm:w-6/12 rounded-full"
            />
          </div>
        </div>
        <div className="lg:w-9/12 text-center mx-auto md:mt-6 flex flex-col justify-center items-center gap-4 font-extralight">
          <p>
            Con más de 3 años de experiencia en desarrollo de software, he
            trabajado en backend con tecnologías como Node.js, Express y Python,
            así como en frontend con React, creando soluciones innovadoras e
            intuitivas. Mi interés por la IA me ha llevado al desarrollo de
            aplicaciones basadas en modelos de lenguaje (LLMs). Además, tengo
            experiencia en bases de datos como PostgreSQL y MongoDB, e
            integración con servicios en la nube como AWS. Busco colaborar en
            equipos que valoren la creatividad, la innovación y el impacto en la
            tecnología.
          </p>
        </div>
        <CTA />
      </div>
    </section>
  );
};

export default About;
