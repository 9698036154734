import React from 'react';
import HeaderSocials from './HeaderSocials';

const Header = () => {
  return (
    <header className='h-screen pt-56 sm:pt-80 overflow-hidden sm:h-screen'>
      <div className='container header__container text-center h-full relative'>
        <h5 className='text-3xl'>Hola, soy</h5>
        <h1 className='text-7xl'>Percy Tejada</h1>
        <h5 className='text-light mt-4'>Desarrollador FullStack</h5>
        <HeaderSocials />
        <a
          href='#contact'
          className='hidden scroll_down sm:block absolute -right-9 bottom-20 font-light text-xs rotate-90'
        >
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
